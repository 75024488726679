<script>
  import FixdCareImg from '@/images/fixd-care-banner.png'
  import Timer from '@/components/Timer.svelte'

  import {
    Logo,
    FixdLogoText,
    ShoppingCartSolid,
    ShoppingCartOutline,
  } from '@/components/icons'

  export let float = false
  export let darkTheme = true
  export let showMenu = true
  export let timer = null
  export let fixdCare = false
  export let buyUrl = '/buy'
  export let timeInSeconds
  export let centerLogo = false
  export let height = 'normal'
  export let fillShoppingCardIcon = true
  export let whiteTimer = false

  const heightVariants = {
    normal: 'h-[72px]',
    small: 'h-[52px]',
  }
</script>

<style>
  .banner.float {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
  }

  .buy-button {
    /* TODO: use consistent border-radius throughout app */
    border-radius: 20px;
    padding: 6px 16px;
    display: flex;
    align-items: center;
  }

  .buy-button.small {
    padding: 4px 10px;
  }

  .buy-button .button {
    padding-left: 0;
    padding-right: 0;
    font-size: 16px;
  }
</style>

<div class="banner" class:float class:bg-black={darkTheme}>
  <div
    class="flex items-center m-auto {heightVariants[
      height
    ]} container max-w-[940px] px-5 {centerLogo
      ? 'justify-center'
      : 'justify-between'}"
  >
    {#if fixdCare}
      <img src={FixdCareImg} alt="fixd-care-logo" class="h-7" />
    {:else}
      <div class="flex items-center gap-2">
        <Logo
          color={darkTheme ? 'white' : 'green'}
          size={height === 'normal' ? 'md' : 'sm'}
        />
        <FixdLogoText
          color={darkTheme ? 'white' : '#3F4147'}
          size={height === 'normal' ? 'md' : 'sm'}
        />
      </div>
    {/if}

    {#if timer}
      <Timer at={timer} {timeInSeconds} whiteText={darkTheme} {whiteTimer}>
        <p class={darkTheme && 'text-white'}>Offer expired!</p>
      </Timer>
    {/if}
    {#if showMenu}
      <a
        href={buyUrl}
        class="buy-button bg-green no-underline"
        class:small={height === 'small'}
      >
        <div class="mr-2">
          {#if fillShoppingCardIcon}
            <ShoppingCartSolid size="sm" color="white" />
          {:else}
            <ShoppingCartOutline size="sm" color="white" />
          {/if}
        </div>
        <span class="text-white font-bold">Buy Now</span>
      </a>
    {/if}
  </div>
</div>

{#if float}
  <!-- The menu is fixed to the top of the page, but we want the content to start
  after it when scrolled to the top. This element consumes the space under the
  menu when scrolled up. -->
  <div class="h-[72px]" />
{/if}
